// src/components/AudioPlayer.tsx

import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

interface AudioPlayerProps {
  url: string;
}

const CustomAudioPlayer: React.FC<AudioPlayerProps> = ({ url }) => {
  return (
    <AudioPlayer
      autoPlay={true}
      src={url}
      onPlay={(e) => console.log("onPlay")}
      onEnded={(e) => console.log("onEnded is called")}
      onError={(e) => console.log("onError is called")}
      onPlayError={(e) => console.log("onPlayError is called")}
      onAbort={(e) => console.log("onAbort is called")}
      // other props here
    />
  );
};

export default CustomAudioPlayer;
