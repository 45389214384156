// src/components/BarcodeScanner.tsx

import React, { useEffect } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";

interface BarcodeScannerProps {
  onScan: (result: string) => void;
  onError: (error: string) => void;
}

const BarcodeScanner: React.FC<BarcodeScannerProps> = ({ onScan, onError }) => {
  useEffect(() => {
    const html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      {
        fps: 10,
        qrbox: { width: 250, height: 250 },
        showZoomSliderIfSupported: true,
        defaultZoomValueIfSupported: 2,
      },
      false
    );

    const onScanSuccess = (decodedText: string, decodedResult: any) => {
      // Handle the scanned code as you like, for example:
      console.log(`Code matched = ${decodedText}`, decodedResult);
      onScan(decodedText);
    };

    const onScanFailure = (error: any) => {
      // Handle scan failure, usually better to ignore and keep scanning.
      onError(error);
    };

    html5QrcodeScanner.render(onScanSuccess, onScanFailure);

    return () => {
      html5QrcodeScanner.clear().catch((error) => {
        console.error("Failed to clear html5QrcodeScanner. ", error);
      });
    };
  }, []);

  return <div id="reader" style={{ width: "300px", height: "300px" }} />;
};

export default BarcodeScanner;
