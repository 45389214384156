// src/components/StationPage.tsx

import React, { useState, useEffect, useRef } from "react";
import { Record } from "./StationPage";

interface RecordItemProps {
  record: Record;
}

const RecordItem: React.FC<RecordItemProps> = ({ record }) => {
  return (
    <div
      className="record-item"
      style={{ backgroundImage: `url("${record.imageUrl}")` }}
    >
      <div className="record-title">{record.title}</div>
      <div className="record-artists">{record.artists}</div>
    </div>
  );
};

export default RecordItem;
