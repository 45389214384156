// src/components/StationPage.tsx

import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import BarcodeScanner from "./BarcodeScanner";
import RecordItem from "./RecordItem";

interface Station {
  id: string;
  name: string;
  description: string;
  published: boolean;
  currentlyPlaying?: Record;
}

export type Record = {
  id: string;
  title: string;
  artists: string;
  imageUrl: string;
  year: string;
  label: string;
};

const StationPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [station, setStation] = useState<Station | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [record, setRecord] = useState<Record | null>(null);
  const [recordError, setRecordError] = useState<string>();
  const [loadingRecord, setLoadingRecord] = useState<boolean>(false);
  const [isScanning, setIsScanning] = useState<boolean>(false);
  const nameRef = useRef<HTMLHeadingElement>(null);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const publishedRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchStation = async () => {
      try {
        const response = await axios.get(`/api/stations/${id}`);
        setStation(response.data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          console.log({ err });
          if (err.response?.status === 404) {
            setError("Station not found.");
          } else {
            setError("Error fetching station details.");
          }
        } else {
          setError("Error fetching station details.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchStation();
  }, [id]);

  const handleSave = async () => {
    if (!station) return;

    const updatedStation = {
      name: nameRef.current?.innerText || "",
      description: descriptionRef.current?.innerText || "",
      published: true,
    };

    try {
      await axios.post(`/api/stations/${id}`, updatedStation);
      setStation({ ...station, ...updatedStation });
      setError(null);
    } catch (err) {
      setError("Error saving station details.");
    }
  };

  const onScan = (result: string) => {
    setIsScanning(false);
    setRecord(null);
    setRecordError("");
    setLoadingRecord(true);
    axios
      .post(`/api/stations/${id}/barcode`, { barcode: result })
      .then((response) => {
        const record = response.data;
        setRecord(record);
        setLoadingRecord(false);
      })
      .catch((error) => {
        setLoadingRecord(true);
        setRecordError("Could not find record.");
      });
  };

  if (loading) {
    return (
      <div className="stations-container">
        <h4>Loading...</h4>
      </div>
    );
  }

  if (error) {
    return (
      <div className="stations-container">
        <h4>{error}</h4>
        <BarcodeScanner onScan={onScan} onError={(err) => {}} />
      </div>
    );
  }

  if (!station) {
    return (
      <div className="stations-container">
        <h4>Station not found.</h4>
      </div>
    );
  }

  const setAsNowPlaying = async () => {
    // make a post request to `api/stations/{id}/now-playing` with the record data
    try {
      const response = await axios.post(`/api/stations/${id}/record`, {
        record,
      });
      setStation(response.data);
      setRecordError("");
      setRecord(null);
    } catch (error) {
      setRecordError("Error setting record as now playing.");
    }
  };

  const removeNowPlaying = async () => {
    try {
      const response = await axios.post(`/api/stations/${id}/record`, {
        record: null,
      });
      setStation(response.data);
      setRecordError("");
    } catch (error) {
      setRecordError("Error removing now playing record.");
    }
  };

  return (
    <div className="stations-container">
      <h1 contentEditable ref={nameRef} suppressContentEditableWarning={true}>
        {station.name}
      </h1>
      <h4
        contentEditable
        ref={descriptionRef}
        suppressContentEditableWarning={true}
      >
        {station.description}
      </h4>
      <div className="currently-playing">
        <div
          style={{
            fontSize: 14,
            textAlign: "left",
            width: "100%",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          Now Playing
        </div>
        {loadingRecord && <div>Searching for your record...</div>}
        {!record && station.currentlyPlaying && (
          <RecordItem record={station.currentlyPlaying} />
        )}
        {!record && station.currentlyPlaying && (
          <div className="action-button" onClick={removeNowPlaying}>
            Remove Now Playing
          </div>
        )}
        {record && <RecordItem record={record} />}
        {record && (
          <div className="action-button" onClick={setAsNowPlaying}>
            Set as Now Playing
          </div>
        )}
        {recordError && <div>{recordError}</div>}
        {isScanning && (
          <>
            <div
              className="action-button action-button-stop"
              onClick={() => setIsScanning(false)}
            >
              Close Barcode Scanner
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <BarcodeScanner onScan={onScan} onError={(err) => {}} />
            </div>
          </>
        )}
        {!isScanning && (
          <div className="action-button" onClick={() => setIsScanning(true)}>
            Open Barcode Scanner
          </div>
        )}
      </div>
      <div
        style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
      >
        <div className="action-button" onClick={handleSave}>
          Save Changes
        </div>
      </div>
    </div>
  );
};

export default StationPage;
