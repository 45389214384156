// src/components/Home.tsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import CustomAudioPlayer from "./AudioPlayer";
import "./Home.css";
import RecordItem from "./RecordItem";
import { Record } from "./StationPage";

interface Station {
  streamId: string;
  name: string;
  description?: string;
  currentlyPlaying?: Record;
}

const Home: React.FC = () => {
  const [stations, setStations] = useState<Station[]>([]);
  const [currentStation, setCurrentStation] = useState<string | null>(null);

  useEffect(() => {
    axios
      .get("https://turn.fm/api/stations")
      .then((response) => {
        setStations(response.data);
      })
      .catch((error) => {
        console.error("Error fetching stations:", error);
      });
  }, []);

  return (
    <div className="stations-container">
      <div className="brand-container">
        <div
          className={currentStation ? "app-icon app-icon-animated" : "app-icon"}
        />
        {currentStation &&
          stations
            .filter(
              (station) => `/streams/${station.streamId}` === currentStation
            )
            .map(
              (station) =>
                station.currentlyPlaying && (
                  <RecordItem
                    key={station.currentlyPlaying.id}
                    record={station.currentlyPlaying}
                  />
                )
            )}
        {currentStation && <CustomAudioPlayer url={`${currentStation}.mp3`} />}
      </div>
      <div className="stations-list">
        {stations.length === 0 && (
          <h4 style={{ textAlign: "center" }}>
            No live stations at the moment
          </h4>
        )}
        {stations.map((station) => (
          <div className="station-container" key={station.streamId}>
            <div className="station-name">{station.name}</div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {station.description && (
                <div className="station-description">{station.description}</div>
              )}
              <div
                className={
                  currentStation === `/streams/${station.streamId}`
                    ? "action-button action-button-stop"
                    : "action-button"
                }
                onClick={() => {
                  if (currentStation === `/streams/${station.streamId}`) {
                    setCurrentStation(null);
                  } else {
                    setCurrentStation(`/streams/${station.streamId}`);
                  }
                }}
              >
                {currentStation === `/streams/${station.streamId}`
                  ? "Stop"
                  : "Tune In "}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
